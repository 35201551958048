export enum DEFAULT_ORDER_VIEW_NAME {
  all = 'all',
  myTasks = 'my_tasks',
  proposedOrders = 'proposed_orders',
  // Костыль для переадресации на дефолтный view
  fakeViewName = 'orderViewName'
}

export enum ORDER_CUSTOM_VISIBILITY_FIELD {
  createdAt = 'createdAt',
  visitDateIntervals = 'visitDateIntervals',
  status = 'status',
  brand = 'brand',
  technicians = 'technicians',
  customerPhones = 'customer.phones',
  region = 'region',
  address = 'address',
  productDeliveryIntervals = 'productDeliveryIntervals',
  customerName = 'customer.name',
  serviceProvider = 'serviceProvider',
  departments = 'departments',
  enterprise = 'enterprise',
}
