export enum FUNDS_TRANSACTION_TYPE {
  addition = 'ADDITION',
  deduction = 'DEDUCTION',
}

export enum DEDUCTION_FROM_SERVICE_TYPE {
  clientPrice = 'CLIENT_PRICE',
  providerReward = 'PROVIDER_REWARD',
}

export enum FUNDS_TRANSACTION_USAGE_TYPE {
  order = 'ORDER',
  invoice = 'INVOICE',
}

export enum ORDER_PROVIDER_DEFAULT_TRANSACTION {
  orderRemuneration = 'order-remuneration',
  partialOrderRemuneration = 'partial-order-remuneration',
  cashOnSpotToProvider = 'cash-on-spot-to-provider',
}

export enum FUNDS_TRANSACTION_CALCULATION_METHOD_TYPE {
  fixedPrice = 'FIXED_AMOUNT',
  percentFromTechnicianReward = 'PERCENT_FROM_PROVIDER_REWARD',
  percentFromDamageSum = 'PERCENT_FROM_DAMAGE_AMOUNT',
  percentFromProductCategory = 'PERCENT_FROM_PRODUCT_CATEGORY',
  technicianQualificationCoefficient = 'TECHNICIAN_QUALIFICATION_COEFFICIENT',
}

export enum FUNDS_TRANSACTION_GROUP {
  system = 'SYSTEM',
  custom = 'CUSTOM',
  automatic = 'AUTOMATIC',
}

export enum AutomaticFundsTransactionTrigger {
  instantOnCondition = 'INSTANT_ON_CONDITION',
  afterOrderCompletionOnCondition = 'AFTER_ORDER_COMPLETION_ON_CONDITION',
}

export enum AutomaticFundsTransactionConditionType {
  orderVisitIntervalOverdue = 'ORDER_VISIT_INTERVAL_OVERDUE',
  orderVisitIntervalMissed = 'ORDER_VISIT_INTERVAL_MISSED',
  orderCompletionOverdue = 'ORDER_COMPLETION_OVERDUE',
  orderLabelValue = 'ORDER_LABEL_VALUE',
  orderNoLabelValue = 'ORDER_NO_LABEL_VALUE',
  orderCustomFieldValue = 'ORDER_CUSTOM_FIELD_VALUE',
  orderProductCategoryValue = 'ORDER_PRODUCT_CATEGORY_VALUE',
  orderTechnicianQualificationCoefficientExists = 'ORDER_TECHNICIAN_QUALIFICATION_COEFFICIENT_EXISTS',
}

export enum FUNDS_TRANSACTION_COUNTERPARTY_TYPE {
  provider = 'PROVIDER',
  platform_operator = 'PLATFORM_OPERATOR',
}
